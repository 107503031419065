import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Skill from "../views/Skill.vue";
import Rest from "../views/Rest.vue";
import Point from "../views/Point.vue";
import Pay from "../views/Pay.vue";
import Welcome from "../views/Welcome.vue";
import User_Welcome from "../views/User/Welcome.vue";
import User_Home from "../views/User/Home.vue";
import User_ChangePass from "../views/User/PassChange.vue";
import { projectAuth } from "@/firebase/config";
import useAdminLogin from "@/components/composables/useAdminLogin";
import User_PayData from "../views/User/UserPayData.vue";
import Training from "@/views/Training.vue";
import Election from "@/views/Election.vue";
import MVP from "@/views/MVP.vue";
import SignupForm from "@/components/SignupForm.vue";
// ルール
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("current user in auth guard:", user);
  if (!user) {
    next({ name: "User" });
  } else {
    next();
  }
};

const requireAdminAuth = async (to, from, next) => {
  const { isAdmin } = useAdminLogin();
  const admin = projectAuth.currentUser;
  if (!admin) {
    next({ name: "Admin_Welcome" });
    return;
  }

  try {
    const res = await isAdmin(admin.email);
    if (!res) {
      next({ name: "Admin_Welcome" });
    } else {
      next();
    }
  } catch (err) {
    console.error("Error in requireAdminAuth:", err);
    next({ name: "Admin_Welcome" });
  }
};

const routes = [
  {
    path: "/admin",
    name: "Admin_Welcome",
    component: Welcome,
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignupForm,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    props: true,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/skill",
    name: "Skill",
    component: Skill,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/rest",
    name: "Rest",
    component: Rest,
    props: true,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/point",
    name: "Point",
    component: Point,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/training",
    name: "Training",
    component: Training,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/election",
    name: "Election",
    component: Election,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/pay",
    name: "Pay",
    component: Pay,
    beforeEnter: requireAdminAuth,
  },
  {
    path: "/",
    name: "User",
    component: User_Welcome,
  },
  {
    path: "/user",
    name: "User_Home",
    component: User_Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/user_pay",
    name: "User_Pay",
    component: User_PayData,
    beforeEnter: requireAuth,
  },
  {
    path: "/pass",
    name: "PassChange",
    component: User_ChangePass,
    beforeEnter: requireAuth,
  },
  {
    path: "/mvp",
    name: "MVP",
    component: MVP,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

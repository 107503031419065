<template>
  <div class="data_whitebox">
    <div class="data_title_box">
      <div @click="handlePageState('entry')" class="tab">
        <h2>がんばったMVPエントリー</h2>
      </div>
      <div @click="handlePageState('graph')" class="tab">
        <h2>がんばったMVP投票状況</h2>
      </div>

      <div class="tab" @click="handlePageState('survey')">
        <h2>アンケート結果</h2>
      </div>
    </div>
    <div class="data_wrapper" v-if="isPageState === 'entry'">
      <div class="data_innerbox">
        <h3>【仕事の部（若手枠）】</h3>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>タイトル</th>
              <th>名前</th>
              <th>エリア</th>
              <th>担当者</th>

              <th>エントリー</th>
              <th>承認</th>
              <th>いいね</th>
              <th>コメント</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in JobYoungEntrys" :key="index">
              <td class="table_no">{{ index + 1 }}</td>

              <td style="overflow-x: auto">
                <div class="entry_title" @click="handleClickEntry(userdata.user_id)">
                  {{ userdata.entry_title }}
                </div>
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>

              <td>
                <div v-if="!userdata.is_entry">未エントリー</div>
                <div v-else>済み</div>
              </td>
              <td>
                <div v-if="userdata.is_agree === true">承認</div>
                <div v-if="userdata.is_agree === ''">未承認</div>
                <div v-if="userdata.is_agree === false">否認</div>
              </td>
              <td>
                <div v-if="userdata.iine_members">{{ userdata.iine_members.length }}</div>
              </td>
              <td>
                <div class="comment_frame">
                  <ol>
                    <li v-for="comment in userdata.user_comments" :key="comment">{{ comment.comment }}{{ comment.user }}
                    </li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>【仕事の部（中堅枠）】</h3>
      <div class="data_innerbox">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>タイトル</th>
              <th>名前</th>

              <th>エリア</th>
              <th>担当者</th>

              <th>エントリー</th>
              <th>承認</th>
              <th>いいね</th>
              <th>コメント</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in JobMiddleEntrys" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td style="overflow-x: auto">
                <div class="entry_title" @click="handleClickEntry(userdata.user_id)">
                  {{ userdata.entry_title }}
                </div>
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>

              <td>
                <div v-if="!userdata.is_entry">未エントリー</div>
                <div v-else>済み</div>
              </td>
              <td>
                <div v-if="userdata.is_agree === true">承認</div>
                <div v-if="userdata.is_agree === ''">未承認</div>
                <div v-if="userdata.is_agree === false">否認</div>
              </td>
              <td>
                <div v-if="userdata.iine_members">{{ userdata.iine_members.length }}</div>
              </td>
              <td>
                <div class="comment_frame">
                  <ol>
                    <li v-for="comment in userdata.user_comments" :key="comment">{{ comment.comment }}{{ comment.user }}
                    </li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>【仕事の部（ベテラン枠）】</h3>
      <div class="data_innerbox">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>タイトル</th>
              <th>名前</th>
              <th>エリア</th>
              <th>担当者</th>

              <th>エントリー</th>
              <th>承認</th>
              <th>いいね</th>
              <th>コメント</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in JobSeniorEntrys" :key="index">
              <td class="table_no">{{ index + 1 }}</td>

              <td style="overflow-x: auto">
                <div class="entry_title" @click="handleClickEntry(userdata.user_id)">
                  {{ userdata.entry_title }}
                </div>
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td>
                <div v-if="!userdata.is_entry">未</div>
                <div v-else>済み</div>
              </td>
              <td>
                <div v-if="userdata.is_agree === true">承認</div>
                <div v-if="userdata.is_agree === ''">未承認</div>
                <div v-if="userdata.is_agree === false">否認</div>
              </td>
              <td>
                <div v-if="userdata.iine_members">{{ userdata.iine_members.length }}</div>
              </td>
              <td>
                <div class="comment_frame">
                  <ol>
                    <li v-for="comment in userdata.user_comments" :key="comment">{{ comment.comment }}{{ comment.user }}
                    </li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>【プライベートの部】</h3>
      <div class="data_innerbox">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>タイトル</th>
              <th>名前</th>
              <th>エリア</th>
              <th>担当者</th>

              <th>エントリー</th>
              <th>承認</th>
              <th>いいね</th>
              <th>コメント</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in PrivateEntrys" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td style="overflow-x: auto">
                <div class="entry_title" @click="handleClickEntry(userdata.user_id)">
                  {{ userdata.entry_title }}
                </div>
              </td>

              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td>
                <div v-if="!userdata.is_entry">未</div>
                <div v-else>済み</div>
              </td>
              <td>
                <div v-if="userdata.is_agree === true">承認</div>
                <div v-if="userdata.is_agree === ''">未承認</div>
                <div v-if="userdata.is_agree === false">否認</div>
              </td>
              <td>
                <div v-if="userdata.iine_members">{{ userdata.iine_members.length }}</div>
              </td>
              <td>
                <div class="comment_frame">
                  <ol>
                    <li v-for="comment in userdata.user_comments" :key="comment">{{ comment.comment }}{{ comment.user }}
                    </li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isPageState === 'graph'">
      <MVPVoteResult :youngEntry="JobYoungEntrys" :middleEntry="JobMiddleEntrys" :beteranEntry="JobSeniorEntrys"
        :privateEntry="PrivateEntrys"></MVPVoteResult>
    </div>
    <div v-if="isPageState === 'survey'">
      <MVPServeyResult />
    </div>
  </div>
</template>

<script>
import DefaultStatus from "./DefaultStatus.vue";
import { ref, onMounted, watch } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import getDefaultDatas from "../composables/getDefaultDatas";

import { projectFirestore } from "@/firebase/config";
import MVPVoteResult from "./MVPVoteResult.vue";
import MVPServeyResult from "./MVPServeyResult.vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    AreaStatus,
    PICStatus,
    MVPVoteResult,
    MVPServeyResult,
  },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
    };
  },

  setup(props, context) {
    const formatTime = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}時間${minutes}分`;
    };
    const currentPage = ref(1);
    const usersPayDatas = ref([]);

    const fetchedMvpDatas = ref([]);
    const { defaultdatas, datas_load } = getDefaultDatas("engineers");

    const JobYoungEntrys = ref([]);
    const JobMiddleEntrys = ref([]);
    const JobSeniorEntrys = ref([]);
    const PrivateEntrys = ref([]);
    const fetchMvpDatas = async () => {
      try {
        await datas_load();

        const combinedEntries = {};

        const res = await projectFirestore.collection("mvp").get();
        res.docs.forEach((doc) => {
          const data = doc.data();
          const userId = data.userId;
          const docId = doc.id;
          console.log("data", data);

          combinedEntries[userId] = {
            doc_id: docId,
            user_id: userId,
            is_entry: data.is_entry,
            is_agree: data.is_agree || "",
            message_for_member: data.message_for_member,
            reason: data.reason,
            genre: data.selected_theme?.genre || null,
            entry_title: data.title,
            engineer_level: data.engineer_level,
            ideas: data.ideas,
            to_be: data.to_be,
            to_get: data.to_get,
            entry_name_request: data.entry_name_request,
            entry_name: data.entry_name,
            detail: data.detail_content,
            img_url: data.img,
            iine_members: data.iine_members,
            user_comments: data.user_comments
          };
        });
        const result = Object.values(combinedEntries);

        console.log("result", result);

        const userMap = {};
        defaultdatas.value.forEach((user) => {
          userMap[user.company_id] = {
            first_name: user.first_name,
            last_name: user.last_name,
            pic: user.pic,
            status: user.status,
            area: user.area,
          };
        });

        fetchedMvpDatas.value = result.map((item) => ({
          ...item,
          first_name: userMap[item.user_id]?.first_name || "",
          last_name: userMap[item.user_id]?.last_name || "",
          pic: userMap[item.user_id]?.pic || "",
          status: userMap[item.user_id]?.status || "",
          area: userMap[item.user_id]?.area || "",
        }));

        console.log("fetchdata", fetchedMvpDatas.value);

        JobYoungEntrys.value = fetchedMvpDatas.value.filter(
          (item) => item.genre === "仕事" && item.engineer_level === "若手"
        );

        JobMiddleEntrys.value = fetchedMvpDatas.value.filter((item) => {
          return item.genre === "仕事" && item.engineer_level === "中堅";
        });

        JobSeniorEntrys.value = fetchedMvpDatas.value.filter((item) => {
          return item.genre === "仕事" && item.engineer_level === "ベテラン";
        });

        PrivateEntrys.value = fetchedMvpDatas.value.filter((item) => {
          return item.genre === "プライベート";
        });
      } catch (error) {
        console.error(error);
      }
    };

    const emit = context.emit;
    const handleClickEntry = (id) => {
      const engineerData = fetchedMvpDatas.value.find(
        (item) => item.user_id === id
      );
      if (engineerData) {
        emit("selected_engineer_id", engineerData);
      }
    };

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    };
    console.log("props.page", props.now_page);
    if (props.now_page === 0) {
      changePage(1);
    } else {
      changePage(props.now_page);
    }
    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
    }

    const isPageState = ref("entry");
    const handlePageState = (state) => {
      if (state === "entry") {
        isPageState.value = "entry"
      } else if (state === "graph") {
        isPageState.value = "graph"
      } else if (state === "survey") {
        isPageState.value = "survey"
      } else {
        isPageState.value = "entry"
      }
      console.log("state", isPageState.value)

    };


    onMounted(async () => {
      await fetchMvpDatas();
    });
    return {
      currentPage,
      changePage,
      usersPayDatas,
      fetchMvpDatas,
      fetchedMvpDatas,
      formatTime,
      JobYoungEntrys,
      JobMiddleEntrys,
      JobSeniorEntrys,
      PrivateEntrys,
      handleClickEntry,
      isPageState,
      handlePageState,

    };
  },

  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit(
        "selected_engineer_id",
        this.selectedItems[0],
        this.currentPage
      );
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
  },
};
</script>

<style scoped>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.data_whitebox {
  width: auto;
  height: 1600px;
  border-radius: 16px;
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.data_wrapper {
  width: 100%;
  height: auto;

  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.data_innerbox {
  width: auto;
}

.table_no {
  text-align: center;
}

.user_name {
  width: 180px;
  white-space: nowrap;
}

.user_name:hover {
  font-weight: bold;
  cursor: pointer;
}

.entry_title {
  width: 300px;
}

.entry_title:hover {
  font-weight: bold;
  cursor: pointer;
}

.data_innerbox th {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  border: 1px solid black;
}

.data_innerbox td {
  border: 1px solid black;
}

.tab {
  cursor: pointer;
}

.tab:hover {
  color: #ddd;
}

table {
  /* will-change: 100%; */
  border-collapse: collapse;
}

.comment_frame {
  width: 250px;
  height: 60px;
  overflow-y: auto;
  margin-left: -20px;
  font-size: 12px;

}
</style>

<template>
  <div class="data_view_box">
    <div class="data_flex">
      <div class="data_item">
        <h3>プライベートの部</h3>
        <MvpVoteGraph :voteDatas="privateMvpResults"></MvpVoteGraph>
      </div>
      <div class="data_item">
        <h3>仕事の部（若手枠）</h3>
        <MvpVoteGraph :voteDatas="youngJobMvpResults"></MvpVoteGraph>
      </div>
    </div>
    <div class="data_flex">
      <div class="data_item">
        <h3>仕事の部（中堅枠）</h3>
        <MvpVoteGraph :voteDatas="middleJobMvpResults"></MvpVoteGraph>
      </div>
      <div class="data_item">
        <h3>仕事の部（ベテラン枠）</h3>
        <MvpVoteGraph :voteDatas="beteranJobMvpResults"></MvpVoteGraph>
      </div>
    </div>
  </div>
</template>
<script>
  import { onMounted, ref, toRaw } from "vue";
  import MvpVoteGraph from "./MvpVoteGraph.vue";
  import { projectFirestore } from "@/firebase/config";

  export default {
    props: {
      youngEntry: {
        type: Array,
        required: true,
      },
      middleEntry: {
        type: Array,
        required: true,
      },
      beteranEntry: {
        type: Array,
        required: true,
      },
      privateEntry: {
        type: Array,
        required: true,
      },
    },
    components: {
      MvpVoteGraph,
    },

    setup(props) {
      const fechedMvpVoteAllDatas = ref([]);
      const privateMvpResults = ref([]);
      const youngJobMvpResults = ref([]);
      const middleJobMvpResults = ref([]);
      const beteranJobMvpResults = ref([]);
      const fetchMvpVoteData = async () => {
        try {
          const res = await projectFirestore
            .collection("mvp_vote_result")
            .get();
          fechedMvpVoteAllDatas.value = res.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const privateResultMap = fechedMvpVoteAllDatas.value.reduce(
            (acc, entry) => {
              const title = entry.private_mvp;
              if (acc[title]) {
                acc[title].count += 1;
              } else {
                acc[title] = { title, count: 1 };
              }
              return acc;
            },
            {}
          );
          privateMvpResults.value = Object.values(privateResultMap);

          props.privateEntry.forEach((entry) => {
            const rawEntry = toRaw(entry);
            const exists = privateMvpResults.value.some(
              (result) => result.title === rawEntry.entry_title
            );
            if (rawEntry.is_agree) {
              if (!exists) {
                privateMvpResults.value.push({
                  title: rawEntry.entry_title,
                  count: 0,
                });
              }
            }
          });

          const youngResultMap = fechedMvpVoteAllDatas.value.reduce(
            (acc, entry) => {
              const title = entry.young_mvp;
              if (acc[title]) {
                acc[title].count += 1;
              } else {
                acc[title] = { title, count: 1 };
              }
              return acc;
            },
            {}
          );
          youngJobMvpResults.value = Object.values(youngResultMap);

          props.youngEntry.forEach((entry) => {
            const rawEntry = toRaw(entry);
            const exists = youngJobMvpResults.value.some(
              (result) => result.title === rawEntry.entry_title
            );

            if (rawEntry.is_agree) {
              if (!exists) {
                youngJobMvpResults.value.push({
                  title: rawEntry.entry_title,
                  count: 0,
                });
              }
            }
          });

          const middleResultMap = fechedMvpVoteAllDatas.value.reduce(
            (acc, entry) => {
              const title = entry.middle_mvp;
              if (acc[title]) {
                acc[title].count += 1;
              } else {
                acc[title] = { title, count: 1 };
              }
              return acc;
            },
            {}
          );
          middleJobMvpResults.value = Object.values(middleResultMap);

          props.middleEntry.forEach((entry) => {
            const rawEntry = toRaw(entry);
            const exists = middleJobMvpResults.value.some(
              (result) => result.title === rawEntry.entry_title
            );

            if (rawEntry.is_agree) {
              if (!exists) {
                middleJobMvpResults.value.push({
                  title: rawEntry.entry_title,
                  count: 0,
                });
              }
            }
          });

          const beteranResultMap = fechedMvpVoteAllDatas.value.reduce(
            (acc, entry) => {
              const title = entry.beteran_mvp;
              if (acc[title]) {
                acc[title].count += 1;
              } else {
                acc[title] = { title, count: 1 };
              }
              return acc;
            },
            {}
          );
          beteranJobMvpResults.value = Object.values(beteranResultMap);

          props.beteranEntry.forEach((entry) => {
            const rawEntry = toRaw(entry);
            const exists = beteranJobMvpResults.value.some(
              (result) => result.title === rawEntry.entry_title
            );

            if (rawEntry.is_agree) {
              if (!exists) {
                beteranJobMvpResults.value.push({
                  title: rawEntry.entry_title,
                  count: 0,
                });
              }
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      };
      onMounted(async () => {
        await fetchMvpVoteData();
      });

      return {
        fetchMvpVoteData,
        privateMvpResults,
        youngJobMvpResults,
        middleJobMvpResults,
        beteranJobMvpResults,
      };
    },
  };
</script>
<style scoped>
  .data_view_box {
    width: 800px;
    height: 800px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
  }

  .data_flex {
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
  }

  .data_item {
    width: 450px;
    height: 400px;
    border: 4px solid #ddd;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
</style>

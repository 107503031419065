<template>
    <div class="result_boxes">


        <div class="mvp_graph_table_box">

            <table>

                <thead>
                    <tr>
                        <th v-for=" entry in voteDatas" :key="entry.title">
                            <div class="bar" :style="{ height: `${entry.count * 5}px` }"></div>
                        </th>
                    </tr>
                    <tr>
                        <td v-for=" (entry, index) in voteDatas" :key="entry.title">{{ index + 1 }}.{{ entry.title }}
                        </td>
                    </tr>
                </thead>

            </table>
        </div>
    </div>


</template>
<script>
export default {
    props: {
        voteDatas: {
            type: Array,
            required: true
        }
    },

    setup() {


        // const electedEntrySize = ref(0);
        // const baseLine = ref(0);
        // const countRest = ref(0);






        // const privateEntryCounts = ref({});
        // const youngEntryCounts = ref({});
        // const middleEntryCounts = ref({});
        // const beteranEntryCounts = ref({});

        // const fetchMvpVoteData = async () => {
        //     try {
        //         const res = await projectFirestore.collection("mvp_vote_result").get();
        //         fechedMvpVoteAllDatas.value = res.docs.map(doc => ({
        //             id: doc.id,
        //             ...doc.data()
        //         }));

        //         electedEntrySize.value = res.size;

        //         const counts = {};


        //         fechedMvpVoteAllDatas.value.forEach(data => {
        //             const entry = data.mvp;
        //             if (private_counts[private_entry]) {
        //                 private_counts[private_entry]++;
        //             } else {
        //                 private_counts[private_entry] = 1;
        //             }
        //             privateEntryCounts.value = private_counts;


        //         })
        //     } catch (error) {
        //         console.log("error:", error)
        //     }

        // }



        // onMounted(async () => {
        //     await fetchMvpVoteData()
        //     countRest.value = baseLine.value - electedEntrySize.value;
        // })

        // return {
        //     privateEntryCounts,

        //     baseLine,

        //     countRest
        // }


    }
}
</script>
<style scoped>
table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;


}

.mvp_graph_table_box td {
    font-size: 12px;
    writing-mode: vertical-lr;
    width: auto;
    height: 100px;
}

th {
    width: 50px;
    height: 250px;
    text-align: center;
    vertical-align: bottom;
    /* 棒グラフをヘッダーの上に表示 */
    position: relative;
}

.bar {
    width: 20px;
    margin: 0 auto;
    background-color: #66dbed;
    transition: height 0.3s ease;
}

.result_graph_box {
    width: 900px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
    border: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}

.graph_table_box {
    display: flex;
    height: 300px;
    align-items: flex-end;
    background-color: #eee;
    border-radius: 5px;

}

.result_graph_box h3 {
    margin: 0px;
}

.result_boxes {
    width: 100%;
    display: flex;
}

.count_down {
    width: 200px;
    height: 300px;
    border-radius: 5px;
    background-color: white;
    border: 2px solid #ddd;
    margin: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>

<template>


  <div class="entry_content">
    <h3>エントリー詳細</h3>
    <div v-if="data" class="entry_item">

      <div class="identity_flex">
        <div>
          <h5>ID</h5>
          <div v-if="data">{{ data.user_id }}</div>
        </div>
        <div>
          <h5>氏名</h5>
          {{ data.last_name }}{{ data.first_name }}
        </div>
      </div>


      <div>
        <h5>下書きのエントリー</h5>
        <ul class="pre_entry_ul">
          <li v-for="entry in data.ideas" :key="entry">{{ entry.item }}</li>
        </ul>
      </div>

      <div>
        <h5>エントリー状況</h5>
        <div v-if="data.is_entry">エントリー済み</div>
        <div v-else>未エントリー</div>
      </div>

    </div>
    <div class="entry_item">


      <div class="content_flex_part">
        <h5>挑戦枠</h5>
        <div>
          <div v-if="data.genre === 'プライベート'">【プライベートの部】</div>
          <div v-else>
            <div v-if="data.engineer_level === '若手'">【仕事の部 若手枠】</div>
            <div v-if="data.engineer_level === '中堅'">【仕事の部 中堅枠】</div>
            <div v-if="data.engineer_level === 'ベテラン'">【仕事の部 ベテラン枠】</div>
          </div>
        </div>
      </div>

      <div>
        <h5>タイトル</h5>
        <div>{{ data.entry_title }}</div>
      </div>
      <div>
        <h5>エントリー詳細</h5>
        <div>{{ data.detail }}</div>
      </div>

      <div>
        <h5>あなたは、どうしてそれを頑張ろうと思いましたか？</h5>
        <div>{{ data.reason }}</div>
      </div>

      <div>
        <h5>がんばった結果、どうなりましたか？</h5>
        <div>{{ data.to_be }}</div>
      </div>

      <div>
        <h5>それをがんばったことで、あなたは何を得たと思いますか？</h5>
        <div>{{ data.to_get }}</div>
      </div>



      <div>
        <h5>それをがんばって得たことを通して、MIKのみんなに伝えたいメッセージはありますか。</h5>
        <div>{{ data.message_for_member }}</div>
      </div>


      <div>
        <h5>関連画像</h5>
        <img v-if="data.img_url" :src="data.img_url" alt="">
        <p v-else>画像がありません。</p>
      </div>

    </div>




    <div class="is_agree_area">
      <h4>このエントリーを承認しますか？</h4>
      <div class="flex_buttons">
        <div class="ok_btn" @click="updateIsAgree(true, data.doc_id)">承認</div>
        <div class="reject_btn" @click="updateIsAgree(false, data.doc_id)">否認</div>
      </div>
    </div>
    <div class="page_bottom">
      <button class="return_btn" @click="pushReturn">戻る</button>

    </div>
  </div>



</template>

<script>

import Pulse from "../Pulse.vue";
import { doc, updateDoc } from "firebase/firestore"; // Firestoreから関数をインポート
import { projectFirestore } from "@/firebase/config";



export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
  },
  setup() {

    const updateIsAgree = async (isAgree, doc_id) => {
      try {
        const docRef = projectFirestore.collection("mvp").doc(doc_id);
        await docRef.update({
          is_agree: isAgree
        });

        console.log("アップデートに成功しました。")
      } catch (error) {
        console.log("アップデートに失敗しました。", error.message)
      }

    }

    return { updateIsAgree };
  },
  methods: {
    pushReturn() {
      const is_close = true;
      this.$emit("return-button-click", is_close);
      console.log("emit1");
    },
    pushEdit() {
      const is_push = true;
      this.$emit("switch-edit-screen", is_push);
    },
  },
};
</script>

<style scoped>
.entry_content {
  width: 600px;
  height: auto;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  border-radius: 20px;
}

.identity_flex {
  display: flex;
  gap: 50px;
}

.pre_entry_ul {
  font-size: 14px;
}

.entry_item h5,
h4 {
  margin: 0;
}

.content_flex_part {
  display: flex;
  align-items: center;
}

.title_bar {
  width: 100%;
  height: 30px;
  background-color: rgba(245, 195, 16, 0.8);
  display: flex;

  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  color: #fff;
  justify-content: center;

}

h5 {
  margin: 0px;
}




.past_year {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paybuttton_box {
  width: 100%;

  flex-wrap: wrap;
  display: flex;
  margin-bottom: 50px;

}

.month_button {
  width: 250px;
  height: 60px;
  background: rgba(245, 195, 16, 1);
  margin: 20px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.month_button:disabled {

  background: #e6e6e3;
  margin: 20px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: not-allowed;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

}

.entry_item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.ok_btn {
  width: 150px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ok_btn:hover {
  cursor: pointer;
}

.reject_btn {
  width: 150px;
  height: 30px;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}


.reject_btn:hover {
  cursor: pointer;
}

.is_agree_area {
  width: 600px;
  height: 100px;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
}


.flex_buttons {
  width: 600px;

  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;

}

.return_btn {
  width: 150px;
  height: 30px;
  background-color: #aaa;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top: 40px;

}

.page_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
